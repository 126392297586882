

.hero{
    height: 90vh;
    width: 100%;
    top: 90px;
    object-fit: contain;
    color: #f4eeff;
}

#video {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}


.hero .hero-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    padding: 1rem;
    text-align: center;
}

.hero p{
    font-size: 1.4rem;
}
.hero .btn {
    margin: 1rem;
}

.bottom-text h2 {
    font-size: 1.6rem;
    text-align: center;
}