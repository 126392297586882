
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root{
  --primary-blue: #00d8ff;
  --primary-purple:#6a00ff;
}

.blue {
  color: var(--primary-blue);
}
body {
  margin: 0;
  font-family: 'kanit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a{
  text-decoration: none;
}

h2 {
  font-size: 3rem;
}

.btn {
  padding: 10px 28px;
  font-size: 1.2rem;
  background-image: linear-gradient(45deg ,var(--primary-purple)0% ,var(--primary-blue)100%);
  color: #000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'kanit' ,sans-serif;
}

.btn-outline {
  background: transparent;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
}

@media screen and (max-width:940px) {

  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width:495px) {
  
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}